/* solution section styling */
.solutions-section {
    width: 100%;
    margin: 0 auto;
    text-align: start;
    margin-bottom: 80px;
    border-bottom: 3px solid lightgray;
    padding-bottom: 80px;
  }
  
  .solutions-h1 {
    padding-bottom: 25px;
    font-size: 45px;
  }
  .solutions-title {
    /* font-size: 24px;
    font-weight: bold; */
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
  }
  
  .solutions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  /* Individual card styling */
  .solution-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding-bottom: 10px;
  }
  
  .solution-card img {
    width: 100%;
    aspect-ratio: 3 / 2; 
    object-fit: cover; 
    border-bottom: 1px solid #ddd;
    border-radius: 10px 10px 0 0; 
  }
  
  
  .solution-card p {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0;
  }
  
  /* Stuff for the partner section */
.partners-section {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 80px;
    border-bottom: 3px solid lightgray;
    padding-bottom: 80px;
  }
  
  .partners-title {
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
  }
  
  .partners-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
  }
  
  /* Profile card styling */
  .profile-card {
    background-color: #f3f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    transition: transform 0.3s ease;
  }
  
  .profile-card:hover {
    transform: translateY(-5px);
  }
  
  .profile-card img {
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 2; 
    object-fit: cover; 
    border-radius: 10px;
  }

  .market-acc-p {
    padding-top: 25px;
  }
  
  .profile-card h2 {
    font-size: 18px;
    margin: 10px 0 5px;
  }
  
  .profile-card p {
    font-size: 14px;
    color: #666;
  }
  
  .bio-button {
    margin-top: 0px;
    padding: 10px 15px;
    font-size: 14px;
    color: #606060;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* .bio-button:hover {
  }
   */

  @media screen and (max-width: 1024px) {
    .partners-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width: 600px) {
    .partners-grid {
      grid-template-columns: 1fr;
    }
  }

  
  /* Modal container */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    overflow-y: auto; 
  }
  
  .modal.show {
    display: flex;
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 20px;
    width: 90%; 
    max-width: 1100px; 
    padding: 30px;
    display: flex;
    flex-direction: column; 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .modal-content .close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 28px;
    cursor: pointer;
  }
  
  .modal-top {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px; 
  }
  
  .modal-top img {
    width: 200px; 
    height: auto;
    border-radius: 10px;
    flex-shrink: 0;
  }
  
  .modal-top-text {
    display: flex;
    flex-direction: column;
  }
  
  .modal-top-text h2 {
    font-size: 28px;
    margin: 0;
    font-weight: bold;
  }
  
  .modal-top-text p {
    font-size: 18px;
    margin: 5px 0 0;
    color: #555;
  }
  

  .modal-bottom h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .modal-bottom p {
    font-size: 16px;
    line-height: 1.6; 
    color: #333;
   
  }
  
  
  @media screen and (max-width: 768px) {
    .modal-content {
      padding: 20px;
      
    }

    .modal-bottom {
      padding: 20px;
      max-height: 50vh; 
      overflow-y: auto; 
      word-wrap: break-word; 
     
      
  }
  
  @media (max-width: 768px) {
      .modal-content {
          max-height: 90vh; 
      }
  
      .modal-bottom {
          max-height: 40vh; 
      }
  }

  .modal-bottom {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 12px;
}

::-webkit-scrollbar:horizontal {
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

    .scrollable-container {
      max-height: 400px; 
      overflow-y: auto; 
      overflow-x: hidden; 
      padding: 10px; 
      border: 1px solid #ccc; 
      scrollbar-width: thin; 
      
  }
  
    .modal-top {
      flex-direction: column; 
      align-items: center;
      text-align: center;
    }
  
    .modal-top img {
      width: 100%; 
      max-width: 200px;
    }

    .homepage-header {
      padding-top: 60px;
      text-align: center;
      font-size: 25px;
    }

    .market-acc-p {
      text-align: justify;
      font-size: 19px !important;
    }

    .solutions-h1 {
      padding-bottom: 40px !important;
      text-align: start !important;

    }

    .solutions-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .solutions-card-p {
      font-size: 12px !important;
      text-align: center;
      padding: 10px;
    }
    .partners-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .partners-title {
      padding-top: 10px;
    }

    .experience-grid {
      grid-template-columns: repeat(1, 1fr)
    }

    .teams-h2 {
      text-align: center !important;
      padding: 0 !important;
      margin: 0 !important;
      font-size: 20px !important;
    }
    .experience-heading {
      padding: 0;
      padding-bottom: 15px;
    }
    .profile-card {
      padding: 0;
      padding-bottom: 10px;
      
    }
    .partners-grid {
      gap: 15px;
    }

    .profile-card img {
      border-radius: 12px 12px 0 0;
    }

    

  }
  