.services-content {
    text-align: center;
    padding: 40px;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 30px; 
    padding: 40px;
  }

 
  
  .service-item {
    
    overflow: hidden;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    transition: transform 0.2s ease;
    text-align: center;
    background-color: #ffffff00;
    margin: auto;
  }
  @media screen and (max-width: 650px) {
    .services-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      padding: 0;
      margin: 0;
    }
    .service-item {
      width: 80%;
    }
    .no-underline {
      width: 100%;
    }
  }
  .service-item:hover {
    transform: translateY(-5px);
    
  }
  
  .service-img {
    object-fit: cover;
    width: 100%;
    height: 400px;
    display: block;
    border-radius: 15px;
  }
  
  .service-item h3 {
    margin: 15px 0;
    color: #333;
    text-align: left;
    font-size: 26px;
  }