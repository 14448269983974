html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures full viewport height */
}

.main-content {
  flex: 1; /* Pushes footer to the bottom */
}

.footer {
  margin: 0;
  padding: 0;
  background-color: #000;
}

.header-logo {
  width: 120px; /* Adjust size as needed */
  height: auto;
  vertical-align: middle; /* Centers the image vertically */
}

/* Remove min-height on .App-header */
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
