/* AboutUs.css */

/* Global Styles */
.about-page {
    /*font-family: 'Lexend Deca', sans-serif;*/
    color: #333;
}

/* Banner Section */
.about-us {
    text-align: center;
    margin-top: 3rem;
    padding: 2rem;
    background-image: url('../../public/images/contact-banner-placeholder.jpg');
    background-size: cover;
    color: #fff;
}

.about-us-h2 {
    font-size: 2.5rem;
    margin-top: 4rem;
    margin-bottom: 0.5rem;
}

/* Team Section */
.team-heading {
    max-width: 1250px; /* Match the width of the .team-grid */
    margin: 0 auto 1rem; /* Center it within the container */
    padding-left: 2rem; /* Ensure this padding matches the .team-grid's padding */
    text-align: left; /* Left-align the text */
}

/* Heading Styling */
.team-heading h2 {
    font-size: 2rem;
    margin: 0;
    margin-top: 4rem;
    font-weight: bold;
    color: #333;
    display: inline-block; /* Ensures it aligns properly within the container */
}

/* Team Grid */
.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 575px);
    gap: 2rem;
    max-width: 1250px;
    padding: 0 2rem;
    margin: auto;
    margin-top: 1rem;
    justify-content: center;
}

/* Team Card with Fixed Size and Styling */
.team-card {
    background-color: #EDEDED; /* Light grey background */
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
    min-width: 490px;
    width: 40vw;
    max-width: 560px;
    height: 430px;
    margin: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for card depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for scaling and shadow */
}

.team-card:hover {
    transform: scale(1.0125); /* Slightly enlarge the card */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
}

/* Uniform Team Image with object-fit */
.team-image {
    width: 65%;
    height: 250px;
    border-radius: 8px;
    object-fit: cover;
    margin-top: 2rem;
}

/* Name and Title Styling */
.team-card h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: .25rem;
    color: #333;
}

.team-card p {
    font-size: 1rem;
    color: #666; /* Subtle grey for title text */
    margin: 0.25rem 0;
}

/* Biography Button Styling */
.bio-button {
    background: none;
    border: none;
    color: #666; 
    cursor: pointer;
    margin-top: 3rem;
    font-weight: bold;
    font-size: 1rem;
}

.bio-button:hover {
    color: #555;
}

/* Experience Section */
/* Experience Section */
.experience-heading {
    max-width: 1300px; /* Match the width of the .experience-grid */
    margin: 0 auto 1rem; /* Center it within the container with some space below */
    padding-left: 2rem; /* Match this padding with .experience-grid */
    text-align: left; /* Left-align the text */
}
.experience {
    text-align: center;
    padding: 2rem;
    color: #333;
}
.experience-heading h2 {
    font-size: 2rem; /* Match font size with team heading */
    margin: 0;
    margin-top: 3rem; /* Optional: Match top margin if needed */
    font-weight: bold;
    color: #333;
    text-align: left;
    padding-left: 2rem; /* Align with experience grid padding */
}

/* Centered Experience Grid Layout */
.experience-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 1175px; /* Adjusted width to better match the centered column */
    margin: 0 auto; /* Center the grid within the experience section */
}

/* Experience Card Styling */
.experience-card {
    background-color: #EDEDED; /* Light grey background */
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
    height: 100px; /* Set fixed height for uniformity */
}

/* Uniform Logo Size */
.experience-logo {
    max-width: 150px; /* Set logo width for consistency */
    max-height: 90px; /* Set logo height to prevent stretching */
    object-fit: contain; /* Contain to fit inside card */
}

/* Show More Button Styling */
.show-more {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto; /* Centers the button horizontally */
    margin-top: 2rem;
}

.show-more .arrow {
    font-size: 1.5rem;
    line-height: 1;
    color: #666;
    transform: rotate(90deg);
    transition: transform 0.3s; /* Smooth rotation */
}

.show-more .arrow.up {
    transform: rotate(270deg); /* Rotates arrow upwards */
}



/* Modal Styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 2rem;
    max-width: 1000px;
    width: 90%;
    text-align: left;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Modal Header for Image and Info */
.modal-header {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.modal-image {
    width: 250px;
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
}

/* Modal Info Styling Adjustments */
.modal-info {
    flex: 1;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
}

.modal-info h3 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
    color: #333;
}

/* Position styling, slightly smaller and closer to the name */
.modal-info p.position {
    font-size: 1.1rem;
    font-weight: normal;
    color: #666;
    margin-top: 0.25rem; /* Closer to the name */
}

/* LinkedIn and Email group styling */
.modal-info .contact-info {
    margin-top: 1.5rem; /* Space between position and LinkedIn/Email group */
    display: flex;
    flex-direction: column;
    gap: 0.25rem; /* Small gap between LinkedIn and Email */
}

.contact-info {
    margin-top: 1rem; /* Space between position and contact details */
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Small gap between LinkedIn and Email */
}

/* Styling for LinkedIn and Email Links */
.contact-info a, 
.contact-info .email {
    display: flex;
    align-items: center; /* Align icon and text */
    color: #666;
    font-weight: normal;
    text-decoration: none;
    margin: 0;
}

.contact-info i {
    font-size: 1.1rem; /* Adjust icon size */
    margin-right: 0.5rem; /* Add spacing between icon and text */
    color: gray; /* Grey color for icons */
}

.modal-info a,
.modal-info p.email {
    color: #666;
    font-weight: normal;
    text-decoration: none;
    margin: 0;
}
.modal-info a{
    text-decoration: underline;
}


.modal-bio {
    margin-top: 1.5rem;
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}

.modal-content h4 {
    margin-top: 1.5rem;
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
}

/* Divder */
.section-divider {
    border: none;
    border-top: 1px solid #ddd; /* Light gray line */
    margin-top: 5rem;
    margin-bottom: -2rem;
    width: 80%; /* Adjust width to your preference */
}



@media (max-width: 700px) { 

    .about-us-h2 {
        text-align: center !important;
        font-size: 20px !important;
        padding-top: 25px !important;
        padding-bottom: 25px;
    }

    .team-heading {
        text-align: center;
        padding: 0;
    }

    .team-image {
        width: 90%;
    }

    .team-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 20px;
        
    }

    .team-card {
        width: 90% !important;
        height: 400px;
        padding: 0;
        min-width: 0 !important;
    }

    .our-team-h2 {
        text-align: center !important;
        font-size: 20px !important;
        padding-top: 25px !important;
        padding-left: 0 !important;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }
    
    .modal-content {
        background: #fff;
        border-radius: 10px;
        max-width: 90%;
        width: 500px;
        max-height: 90%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
    }
    
    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        height: 30px;
        width: 30px;
    }
    
    .modal-header {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        border-bottom: 1px solid #e0e0e0;
        text-align: center;
    }
    
    .modal-image {
        width: 350px;
        height: 200px;
        object-fit: cover;
    }
    
    .modal-info {
        padding-left: 0 !important;
        
    }

    .bio-h4 {
        text-align: center;
        margin-bottom: 0;
    }
    
    
    
    .contact-info a,
    .contact-info p {
        font-size: 0.9rem;
        color: gray;
        display: flex;
        align-items: center;
    }
    
    .modal-bio {
        padding: 20px;
        overflow-y: auto;
        max-height: 250px;
        -webkit-overflow-scrolling: touch;
        scrollbar-gutter: stable;
        margin: 0;
        font-size: 0.95rem;
        line-height: 1.5;
    }
    
    .modal-bio::-webkit-scrollbar {
        width: 8px;
    }
    
    .modal-bio::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
    }
    
    .modal-bio::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    

}