.banner {
  position: relative;
  width: 100%;
  height: 90vh; 
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white; 
  background-color: rgba(0, 0, 0, 0.5);

}

.background-video,
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; 
}

.banner-logo {
  max-width: 300px;
  margin-bottom: 20px;
}

.banner-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.banner-subtitle {
  font-size: 1.5rem;
  margin-top: 10px;
}

.background-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 600px) {
  .banner {
    height: 60vh;
  }

  .banner-logo {
    /* width: 200px; */
    height: 40px;
    object-fit: cover;
    margin: 0;
  }

  .banner-subtitle {
    font-size: 25px;
  }

}

