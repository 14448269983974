:root {
  --primary-font: Arial, Helvetica, sans-serif;
}

.contact-banner {
  background-image: url('../../public/images/contact-banner-placeholder.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  margin-top: -80px;
}

.contact-main-title {
  font-size: 4rem;
  margin: 0;
  font-family: var(--primary-font);
}

.contact-subtitle {
  font-size: 1.5rem;
  margin-top: 10px;
  font-family: var(--primary-font);
}

.contact-container {
  padding: 20px;
  max-width: 750px;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
}

.contact-title {
  font-weight: bold;
  text-align: center;
  font-size: x-large;
  margin-top: 50px;
  font-family: var(--primary-font);
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.contact-form {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  margin-top: -20px;
  font-family: var(--primary-font);

  width: 100%;
  max-width: 750px;
}
/* .contact-form form{
  width: 100%;
  max-width: 400px;
} */

.form-group {
  margin-bottom: 15px;
}

.form-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  font-family: var(--primary-font);
}

.form-input, .form-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: var(--primary-font);
}

.form-textarea {
  resize: vertical;
  height: 200px;
}

.submit-button {
  display: block;
  width: 100px;
  padding: 10px;
  margin: 20px auto 0;
  background-color: #000;
  color: #fff;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-family: var(--primary-font);
}

.submit-button:hover {
  background-color: #ffffff;
  color: #000000;
}


@media (max-width: 600px) {
  .contact-title {
    text-align: center;
    padding-bottom: 25px;
  }

  .mailchimp-con {
    width: 43vh;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 25px;
  }

  .mail-h2 {
    text-align: center;
  }
}
