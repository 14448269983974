.footer {
    background-color: #000;
    color: #fff;
    padding: 2rem 0;
    font-family: Arial, sans-serif;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    border-bottom: 1px solid #333;
  }
  
  .footer-section {
    flex: 1;
    margin: 0 1rem;
    text-align: left;
    margin-bottom: 15px;
  }
  
  .footer-logo {
    width: 100px;
    height: auto;
    margin: 1rem 0;
  }
  
  .footer-section h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .footer-section p,
  .footer-section address,
  .footer-section ul {
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 1.5;
    margin: 0;
    color: #fff;
  }
  
  .footer-section address {
    font-style: normal;
    color: #fff;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
    margin-top: 0.5rem;
  }
  
  .footer-section ul li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .footer-section ul li .social-icon {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    vertical-align: middle;
  }
  .footer-section ul li a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-section ul li img.social-icon {
    margin-right: 0.5rem;
  }
  
  .footer-bottom {
    text-align: center;
    padding: 1rem 0;
    font-size: 0.8rem;
    background-color: #111;
    color: #fff;
  }
  
  .footer-bottom p {
    margin: 0;
    color: #fff;
  }
  
  .footer-section .newsletter p {
    font-size: 0.9rem;
    font-weight: 300;
    color: #bbb;
  }
  
  .footer-section .newsletter p a {
    color: #00aaff;
    text-decoration: none;
  }
  
  .footer-section .newsletter p a:hover {
    text-decoration: underline;
  }
  .footer-section .newsletter-link {
    color: #fff;
    text-decoration: none;
    font-weight: 300;
  }
  
  .footer-section .newsletter-link:hover {
    text-decoration: underline;
  }
  .footer-section .email-link {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 300;
  }
  .footer-section .email-link img {
    width: 20px;
    height: auto;
    margin-right: 0.5rem;
  }
  
  .footer-section .email-link:hover {
    text-decoration: underline;
  }
  
  .footer-section .email-link img.social-icon {
    margin-right: 0.3rem;
  }
  .footer-bottom .footer-link {
    color: #fff;
    text-decoration: none;
    margin-left: 0.3rem;
  }
  
  .footer-bottom .footer-link:hover {
    text-decoration: underline;
  }
  