/* Header Styling */
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
}

.header-logo {
  width: 120px;
  height: auto;
}


.desktop-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.desktop-menu li {
  margin-left: 2rem;
  position: relative;
}
.dropdown li {
  margin-left: 0 !important;
}

.desktop-menu li a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
}

.desktop-menu li a:hover {
  text-decoration: underline;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 0.5rem 0;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 250px;
  list-style-type: none;
}


.dropdown-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
}

.dropdown-button {
  padding: 0;
  border: none;
  background-color: #ffffff00;
  font-size: 16px;
  font-weight: 500;
}

.dropdown-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  color: black;
}
.mobile-menu-lower {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 20%;
  width: 100%;
  /* background-color: black; */
  backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.765);
    z-index: 10;
    margin: 0;
    padding: 0;
}
.close-mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  color: rgb(181, 181, 181);
  background-color: #ffffff00;
  font-size: 23px;
  font-weight: bold;
  font-stretch: expanded;
  border:none;
  margin: 20px;
}
.mobile-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.mobile-menu li {
  margin: 1rem 0;
}

.mobile-menu a {
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.mobile-dropdown-button {
  background: none;
  border: none;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
  margin: none 0;
  font-weight: bold;
  
}

.mobile-submenu {
  margin-top: 1rem;
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  /* background-color: rgba(234, 234, 234, 0.896); */
  width: 100%;
  margin-top: 16px !important; 
}

.mobile-submenu a{
  font-size: 20px;
}

.mobile-submenu li {
  margin: 1.3rem 0;
}

/* Media Query for Mobile */
@media screen and (max-width: 550px) {
  .desktop-menu {
    display: none;
  }

  .hamburger {
    display: flex;
  }
}
